<template>
    <psi-form-layout-section
        title="Change Password"
        icon="mdi-lock"
        btn-label="Change Password"
        btn-icon="mdi-lock-check"
    >
        <psi-form-section
            :value="passwords"
            name="date-input-data"
            label=""
            :fields="passwordFields"
            color="secondary"
            @input="update"
        ></psi-form-section>
    </psi-form-layout-section>
</template>
<script>
export default {
    name: "update-passwords",
    components: {},
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            passwords: {},
            passwordFields: [
                {
                    type: "text",
                    name: "password",
                    key: "password",
                    format: "password",
                    label: "Current Password",
                    icon: this.$config("icons.password"),
                    required: true,
                },
                {
                    type: "text",
                    name: "new_password",
                    key: "new_password",
                    label: "New Password",
                    format: "password",
                    icon: this.$config("icons.password"),
                    required: true,
                },
                {
                    type: "text",
                    name: "confirm_password",
                    key: "confirm_password",
                    label: "Confirm Password",
                    format: "password",
                    icon: this.$config("icons.password"),
                    required: true,
                },
            ],
        };
    },
    computed: {},
    watch: {
        user: {
            immediate: true,
            handler(val) {
                const { email } = val;
                this.passwords = {
                    password: "",
                    new_password: "",
                    confirm_password: "",
                    email,
                };
            },
        },
    },
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {
        update() {},
    },
};
</script>

<style scoped>
</style>
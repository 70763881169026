var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "psi-form-layout-section",
    {
      attrs: {
        title: "Change Password",
        icon: "mdi-lock",
        "btn-label": "Change Password",
        "btn-icon": "mdi-lock-check"
      }
    },
    [
      _c("psi-form-section", {
        attrs: {
          value: _vm.passwords,
          name: "date-input-data",
          label: "",
          fields: _vm.passwordFields,
          color: "secondary"
        },
        on: { input: _vm.update }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }